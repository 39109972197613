.NavBar {
  z-index: 99 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background-color: rgb(51, 51, 51);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 1vw;
  padding: 0 14vw;
  color: white;
  font-size: 2vmax;
  font-family: monospace;
  box-shadow:  0 2px 4px 4px white, 0 -1px 2px -2px white inset;
  opacity: 0.9;
}

.NavButtonBar {
  z-index: 99 !important;
  background-color: rgb(51, 51, 51);
  box-shadow:  0 2px 4px 4px white, 0 -1px 2px -2px white inset;
  opacity: 0.9;
  justify-content: right;
  align-items: center;
  display: flex;
  padding: 0 4vw;
  color: white;
  font-size: 2vmax;
  font-family: monospace;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  position: fixed;
}

.NavButton {
  height: 100%;
  width: 8vmin;
}

.NavBurger {
  z-index: 99 !important;
  position: fixed;
  top: 8vh;
  left: 0;
  width: 100%;
  background-color: rgb(51, 51, 51);
  opacity: 0.97;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  padding: 0 4vw;
  color: white;
  font-size: 4vmax;
  font-family: monospace;
  box-shadow:  0 2px 4px 4px white, 0 -1px 2px -2px white inset;
}