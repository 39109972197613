.wave-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80%;
    margin: 0;
}

.wave {
    display: inline;
    position: relative;
    bottom: 0;
    width: 200%;
    height: 100%;
    margin-top: 0;
    animation: wave-side 8s linear infinite;
}

.wave:not(:first-of-type) {
    margin-top: -100vh;
    opacity: 0.5
}

@keyframes wave-side {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -100%;
    }
}