.ContentBox {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: 96vh;
  z-index: 1;
  color: white;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.Page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: black;
  background-size: 100%;
}

.Anchor {
  padding-top: 20vh;
  margin-top: -20vh;
}

.TextField {
  margin-left: auto;
  max-width: 60%;
  align-self: right;
}